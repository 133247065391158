import { AppContext } from 'Api/AppContext';
import { IUriService } from 'Api/Contracts/Interfaces';
import { injectTypes } from 'App/injectTypes';
import { IVisonUriService } from 'App/Services/UriServices/Core';
import { inject, injectable } from 'inversify';
import asFormData from 'json-form-data';
import urlJoin from 'url-join';

export type HttpVerb = 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH';

@injectable()
export default class HttpClient {
    public constructor(uriService: IUriService) {
        this._uriService = uriService;

        this._requestInit = {
            credentials: 'same-origin',
            headers: new Headers({
                'Accept': 'application/json'
            })
        };
    }

    public async getAsync(relativeUri: string, baseUri: string = null, options?: any, headers?: Headers): Promise<Response> {
        let initRequest = this._initializeInitRequest('GET', null, headers);

        if (options) {
            let params = new URLSearchParams();

            for (let parameter in options) {
                let value = options[parameter];

                if (Array.isArray(value)) {
                    value.forEach(parameterArrayItem => {
                        if (parameterArrayItem != null) {
                            params.append(parameter, parameterArrayItem);
                        }
                    });
                }
                else {
                    if (value != null) {
                        params.append(parameter, value)
                    }
                }
            }

            const queryString = params.toString();

            if (queryString) {
                relativeUri += `?${queryString}`;
            }
        }

        return await this._fetch(baseUri, relativeUri, initRequest);
    }

    public postAsJsonAsync(value: any, baseUri: string = null, relativeUri: string = ''): Promise<Response> {
        return this.postAsync(relativeUri, value, baseUri);
    }

    public putAsJsonAsync(value: any, baseUri: string = null, relativeUri: string = ''): Promise<Response> {
        return this.putAsync(relativeUri, value, baseUri);
    }

    public deleteAsJsonAsync(value: any, baseUri: string = null, relativeUri: string = ''): Promise<Response> {
        return this.deleteAsync(relativeUri, value, baseUri);
    }

    public async patchAsJsonAsync(value: any, baseUri: string = null, relativeUri: string = '', headers?: Headers): Promise<Response> {
        let initRequest = this._initializeInitRequest('PATCH', JSON.stringify(value), headers);

        return await this._fetch(baseUri, relativeUri, initRequest);
    }

    public async postAsync(relativeUri: string, value: any, baseUri: string = null, headers?: Headers): Promise<Response> {
        let initRequest = this._initializeInitRequest('POST', JSON.stringify(value), headers);

        return await this._fetch(baseUri, relativeUri, initRequest);
    }

    public async postFormDataAsync(relativeUri: string, data: any, baseUri: string = null, keepAlive: boolean = false): Promise<Response> {

        let formData = asFormData(data, {
            includeNullValues: false, mapping: value => {
                //TODO : Cast en string à supprimer apres la mise à jour de la définition de la fonction de mapping
                return value as unknown as string;
            }
        });

        if (this.antiForgeryToken) {
            formData.append('__RequestVerificationToken', this.antiForgeryToken);
        }

        let initRequest = Object.assign({}, this._requestInit, {
            method: 'POST',
            body: formData,
            headers: {
                'Accept': 'application/json',
                //'Content-Type': 'application/x-www-form-urlencoded'
            },
            keepalive: keepAlive
        } as RequestInit);

        return await this._fetch(baseUri, relativeUri, initRequest);
    }

    public async putAsync(relativeUri: string, value: any, baseUri: string = null, headers?: Headers): Promise<Response> {
        let initRequest = this._initializeInitRequest('PUT', JSON.stringify(value), headers);

        return await this._fetch(baseUri, relativeUri, initRequest);
    }

    public async deleteAsync(relativeUri: string, value: any, baseUri: string = null, headers?: Headers): Promise<Response> {
        let initRequest = this._initializeInitRequest('DELETE', JSON.stringify(value), headers);

        return await this._fetch(baseUri, relativeUri, initRequest);
    }

    protected _initializeInitRequest(method: HttpVerb, body: any = null, headers: Headers = null): RequestInit {
        let initRequest: RequestInit = {
            ...this._requestInit,
            method: method
        };

        if (body) {
            initRequest.body = body;
        }

        initRequest.headers = new Headers(initRequest.headers);

        for (let h of (this._requestInit.headers as Headers).entries()) {
            initRequest.headers.set(h[0], h[1]);
        }
        for (let h of HttpClient._jsonContentHeader.entries()) {
            initRequest.headers.set(h[0], h[1]);
        }

        if (headers) {
            for (let h of headers.entries()) {
                initRequest.headers.set(h[0], h[1]);
            }
        }

        return initRequest;
    }

    protected buildUri(baseUri: string, relativeUri: string): string {
        return baseUri
            ? urlJoin(baseUri, relativeUri)
            : relativeUri;
    }

    private async _fetch(baseUri: string, relativeUri: string, initRequest: RequestInit): Promise<Response> {
        return await fetch(
            this._uriService.getAbsoluteUri(
                this.buildUri(baseUri, relativeUri)
            ),
            initRequest
        );
    }

    public get bearerToken(): string {
        return this._bearerToken;
    }

    public set bearerToken(token: string) {
        if (token) {
            this._bearerToken = token;
            (this._requestInit.headers as Headers).set('Authorization', `Bearer ${token}`);
        }
        else {
            (this._requestInit.headers as Headers).delete('Authorization');
        }
    }

    public get antiForgeryToken(): string {
        return this._antiForgeryToken;
    }

    public set antiForgeryToken(antiForgeryToken: string) {
        this._antiForgeryToken = antiForgeryToken;
    }

    protected _bearerToken: string;
    protected _antiForgeryToken: string;
    protected readonly _uriService: IUriService;
    protected readonly _requestInit: RequestInit;

    protected static readonly _jsonContentHeader: Headers = new Headers({
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    });
}

@injectable()
export class VisonHttpClient extends HttpClient {
    public constructor(@inject(AppContext) appContext: AppContext, @inject(injectTypes.IVisonUriService) uriService: IVisonUriService) {
        super(uriService);

        this._appContext = appContext;
        this.antiForgeryToken = this._appContext.antiForgeryToken;
    }

    protected buildUri(baseUri: string, relativeUri: string): string {
        return baseUri
            ? urlJoin(baseUri, relativeUri)
            : relativeUri.match(/^\/?api\//)
                ? relativeUri
                : urlJoin(this._appContext.codeCulture, relativeUri);
    }

    protected _initializeInitRequest(method: HttpVerb, body: any = null, headers: Headers = null): RequestInit {
        headers = headers ?? new Headers();

        if (!headers.has('Accept-Language')) {
            headers.set('Accept-Language', this._appContext.codeCulture);
        }

        this.bearerToken = this._appContext.login?.token ?? null;

        return super._initializeInitRequest(method, body, headers);
    }

    protected readonly _appContext: AppContext;
}

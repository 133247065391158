
//Flags
enum RenderType { Spherical = 1, Cube = 2 }

enum CoordinateSystem { Plane, Spherical, Cartesian }

interface IPlanCoordinates {
    x: number;
    y: number;
}

interface ISphericalCoordinates {
    //Horizontal angle. ATH -Pi <-> +Pi
    theta: number;
    //Vertical angle. ATV +Pi/2 <-> -Pi/2
    phi: number;
}

interface ICartesianCoordinates {
    x: number;
    y: number;
    z: number;
}
export type ICoordinates = IPlanCoordinates | ISphericalCoordinates | ICartesianCoordinates;

interface IHotspot<T = any> {
    coordinateSystem: CoordinateSystem;
    coordinates: Array<T>;
    name: string;
    content: any;
    events: any;
    onclick: any;
}

interface ISphericalHotspot extends IHotspot<ISphericalCoordinates> {

}

interface ICartesianHotspot extends IHotspot<ICartesianCoordinates> {
}

interface IConfiguration {
    basePath: string;
    RenderType: RenderType;
    MaxFov?: number;
    MinFov?: number;
    Fov?: number;
    hotspots: Array<IHotspot>;
    iconProvider: IIconProvider;
}

interface IPanoramaConfiguration extends IConfiguration {
    Url: string;
    MaxLevel: number;
    IsMultiResolution: boolean;
    idleRotationWaitTime: number;
    idleRotationSpeed: number;
    Levels: Array<IPanoramaLevel>;
    startOrientation: ICartesianCoordinates;
}
interface IPanoramaLevel {
    ImageWidth: number;
    ImageHeight: number;
    TileSize: number;
}

interface IViewer<TConfiguration = IConfiguration> {
    MaxFov: number;
    MinFov: number;
    FPS: number;
    autoRotationEnabled: boolean;

    //String of base64-encoded characters. This string can be assigned to the src parameter of an <img> to display it
    TakeScreenshot(): Promise<string>;
    ResizeView(): void;
    setSizeView(width: number, height: number): void;

    renderTypeSupported: RenderType;
    loadConfiguration(configuration: TConfiguration)
}

enum TextureType {
    OpacityTexture,
    EmissiveTexture
}

interface IIconInfo {
    url: string;
    textureType: TextureType;
    animation?: {
        nRows: number,
        nCols: number,
        startFrame: number,
        endFrame: number,
        frameRate: number
    };
}

interface IIconProvider {
    getIconInfo(hotspot: IHotspot): IIconInfo;
}

export { RenderType, CoordinateSystem, IPlanCoordinates, ISphericalCoordinates, ICartesianCoordinates, IHotspot, ISphericalHotspot, ICartesianHotspot, IConfiguration, IPanoramaConfiguration, IPanoramaLevel, IViewer, TextureType, IIconInfo, IIconProvider };

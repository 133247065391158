import { IUserLogin } from 'Api/Dto/Account/UserLogin';
import { IGenerateNewApiKeyViewModel } from 'Api/Dto/Account/ViewModels/GenerateNewApiKeyViewModel';
import HttpClient from 'Api/HttpClient';
import { inject, injectable } from 'inversify';
import { IQueryResult, QueryException, IPaginationResult, IQueryResultBase } from 'Api/Dto/QueryResult';
import { Routes } from 'Api/Routes';
import { String } from 'typescript-string-operations';
import { IForgotPasswordViewModel } from 'Api/Dto/Account/ViewModels/ForgotPasswordViewModel';
import { IRegisterViewModel } from 'Api/Dto/Account/ViewModels/RegisterViewModel';
import { IEditEmailViewModel } from 'Api/Dto/Profile/ViewModels/EditEmailViewModel';

@injectable()
export class AccountService {
    constructor(@inject(HttpClient) httpClient: HttpClient) {
        this._httpClient = httpClient;
    }

    public async getTokensAsync(search: string, pageIndex: number = 1, pageSize: number = 100): Promise<IPaginationResult<IUserLogin>> {
        const response = await this._httpClient.getAsync(
            Routes.Api.Account.Me.Tokens,
            null,
            {
                search: search,
                skip: (pageIndex - 1) * pageSize,
                top: pageSize
            }
        );
        const queryResult = await response.json() as IPaginationResult<IUserLogin>;

        if (response.status !== 200) {
            throw new QueryException(queryResult.error);
        }

        return queryResult;
    }

    public async getTokenAsync(tokenId: string): Promise<IUserLogin> {
        let response = await this._httpClient.getAsync(
            Routes.Api.Account.Me.Tokens,
            null,
            {
                tokenId: tokenId
            }
        );
        const queryResult = await response.json() as IQueryResult<IUserLogin>;

        if (response.status !== 200) {
            throw new QueryException(queryResult.error);
        }

        return queryResult.result;
    }

    public async addTokenAsync(keyName: string): Promise<IUserLogin> {
        let response = await this._httpClient.postAsync(
            Routes.Api.Account.Me.Tokens,
            {
                keyName: keyName
            } as IGenerateNewApiKeyViewModel
        );
        const queryResult = await response.json() as IQueryResult<IUserLogin>;

        if (response.status !== 201) {
            throw new QueryException(queryResult.error);
        }

        return queryResult.result;
    }

    public async deleteTokensAsync(tokenIds: Array<string>): Promise<void> {
        let response = await this._httpClient.deleteAsync(
            Routes.Api.Account.Me.Tokens,
            tokenIds
        );

        if (response.status !== 204) {
            const queryResult = await response.json() as IQueryResult<void>;
            throw new QueryException(queryResult.error);
        }
    }

    public async deleteTokenAsync(tokenId: string): Promise<void> {
        let response = await this._httpClient.deleteAsync(
            String.Format(Routes.Api.Account.Me.Token, tokenId),
            null
        );

        if (response.status !== 204) {
            const queryResult = await response.json() as IQueryResult<void>;
            throw new QueryException(queryResult.error);
        }
    }

    public async requestResetPassword(email: string): Promise<void> {
        const response = await this._httpClient.postFormDataAsync(
            'account/forgotpassword',
            { email: email } as IForgotPasswordViewModel
        );

        if (response.status != 204) {
            const queryResult = await response.json() as IQueryResultBase;
            throw new QueryException(queryResult.error);
        }
    }

    public async webRegisterAsync(viewModel: IRegisterViewModel): Promise<void> {
        const response = await this._httpClient.postFormDataAsync(
            'account/register',
            viewModel
        );

        if (response.status != 204) {
            const queryException = await response.json() as IQueryResultBase;
            throw new QueryException(queryException.error);
        }
    }

    public async webUpdateEmailAsync(viewModel: IEditEmailViewModel): Promise<string> {
        const response = await this._httpClient.postFormDataAsync(
            'profile/profile/editemail',
            viewModel
        );
        const queryResult = await response.json() as IQueryResult<string>;

        if (response.status != 200) {
            throw new QueryException(queryResult.error);
        }

        return queryResult.result;
    }

    protected readonly _httpClient: HttpClient;
}

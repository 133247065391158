import { ICoordinates, IPlanCoordinates } from 'Framework/Components/Controls/XView/Core/Coordinates';
import { IHotspot } from 'Framework/Components/Controls/XView/Core/Hotspots';
import { IIconProvider } from 'Framework/Components/Controls/XView/Core/Icons';

export enum RenderType { Spherical = 1, Cube = 2, Flat = 3 }

export interface IConfiguration {
    RenderType: RenderType;
    MaxFov?: number;
    MinFov?: number;
    Fov?: number;
    hotspots: Array<IHotspot>;
    iconProvider: IIconProvider;
}

export interface IPanoramaConfiguration extends IConfiguration {
    Url: string;
    MaxLevel: number;
    IsMultiResolution: boolean;
    idleRotationWaitTime: number;
    idleRotationSpeed: number;
    Levels: Array<IPanoramaLevel>;
    startOrientation: ICoordinates;
}

export interface IFlatConfiguration extends IConfiguration {
    url: string;
    maxLevel: number;
    imageWidth: number;
    imageHeight: number;
    levels: Array<IPanoramaLevel>;
    startPosition: IPlanCoordinates;

    readonly ratio: number;
}

export interface IPanoramaLevel {
    ImageWidth: number;
    ImageHeight: number;
    TileSize: number;
}

export function isPanoramaConfiguration(configuration: IConfiguration): configuration is IPanoramaConfiguration {
    return configuration
        && (configuration.RenderType === RenderType.Cube
            || configuration.RenderType === RenderType.Spherical);
}

export function isFlatConfiguration(configuration: IConfiguration): configuration is IFlatConfiguration {
    return configuration?.RenderType === RenderType.Flat;
}

export class FlatConfiguration implements IFlatConfiguration {
    public constructor(url: string, imageWidth: number, imageHeight: number, getHotspots: () => Array<IHotspot>) {
        this.url = url;
        this.imageWidth = imageWidth;
        this.imageHeight = imageHeight;
        this.RenderType = RenderType.Flat;
        this.iconProvider = null;
        this.maxLevel = 0;
        this.levels = new Array<IPanoramaLevel>();
        this.startPosition = { x: 0.5, y: 0.5 / (imageWidth / imageHeight) };
        this.MaxFov = Math.PI;
        this.MinFov = Math.PI / 180;

        this._getHotspots = getHotspots;
    }

    public RenderType: RenderType;
    public MaxFov?: number;
    public MinFov?: number;
    public Fov?: number;
    public iconProvider: IIconProvider;
    public url: string;
    public maxLevel: number;
    public imageWidth: number;
    public imageHeight: number;
    public levels: Array<IPanoramaLevel>;
    public startPosition: IPlanCoordinates;

    public get hotspots(): Array<IHotspot<any>> {
        return this._getHotspots();
    }

    public get ratio(): number {
        return this.imageWidth / this.imageHeight;
    }

    private readonly _getHotspots: () => Array<IHotspot>;
}

export class PanoramaConfiguration implements IPanoramaConfiguration {
    public constructor(url: string, levels: Array<IPanoramaLevel>, getHotspots: () => Array<IHotspot>) {
        this.Url = url;
        this.RenderType = RenderType.Cube;
        this.MaxLevel = levels.length;
        this.Levels = levels;
        this.Fov = 80 / 180 * Math.PI;
        this.MaxFov = Math.PI / 2;
        this.MinFov = Math.PI / 180;

        this._getHotspots = getHotspots;
    }

    public Url: string;
    public MaxLevel: number;
    public IsMultiResolution: boolean;
    public idleRotationWaitTime: number;
    public idleRotationSpeed: number;
    public Levels: IPanoramaLevel[];
    public startOrientation: ICoordinates;
    public RenderType: RenderType;
    public MaxFov?: number;
    public MinFov?: number;
    public Fov?: number;
    public iconProvider: IIconProvider;

    public get hotspots(): Array<IHotspot<any>> {
        return this._getHotspots();
    }

    private readonly _getHotspots: () => Array<IHotspot>;
}

export abstract class ConfigurationBuilder {
    public static CreateFlatConfigurationWithImage(
        url: string,
        imageWidth: number,
        imageHeight: number,
        getHotspots: () => Array<IHotspot> = () => []
    ): FlatConfiguration {
        return new FlatConfiguration(
            url,
            imageWidth,
            imageHeight,
            getHotspots);
    }

    public static CreatePanoramaConfigurationWithConstantTile(
        url: string,
        levelCount: number,
        getHotspots: () => Array<IHotspot> = () => []
    ): PanoramaConfiguration {
        return new PanoramaConfiguration(
            url,
            [...ConfigurationBuilder.levelPanoramaGenerator(levelCount, true)],
            getHotspots);
    }

    public static CreatePanoramaConfigurationWithComputedTile(
        url: string,
        levelCount: number,
        getHotspots: () => Array<IHotspot> = () => []
    ): PanoramaConfiguration {
        return new PanoramaConfiguration(
            url,
            [...ConfigurationBuilder.levelPanoramaGenerator(levelCount, false)],
            getHotspots);
    }

    private static *levelPanoramaGenerator(
        maxLevel: number,
        constantTileSize: boolean = true
    ): IterableIterator<IPanoramaLevel> {
        for (let level: number = 1; level <= maxLevel; level++) {
            let size: number = Math.pow(2, level - 1);
            let sizeOfImage = size * 512;

            yield {
                ImageWidth: sizeOfImage,
                ImageHeight: sizeOfImage,
                TileSize: constantTileSize ? 512 : sizeOfImage
            };
        }
    }
}
import { AppContext } from 'Api/AppContext';
import { SortOptions } from 'Api/Contracts/Dtos';
import { ForceResetPasswordViewModel } from 'Api/Dto/Account/ViewModels/ForceResetPasswordViewModel';
import { ResetPasswordViewModel } from 'Api/Dto/Account/ViewModels/ResetPasswordViewModel';
import { GenerateType } from 'Api/Dto/Admin/Enums/GenerateType';
import { IGroup } from 'Api/Dto/Admin/Group';
import { IIdentityProvider, ILdapSynchronizationResult } from 'Api/Dto/Admin/IdentityProvider';
import { IRolesWithUsersAndAvailableRoles } from 'Api/Dto/Admin/RolesWithUsersAndAvailableRoles';
import { ISubscription } from 'Api/Dto/Admin/Subscription';
import { IInternalSystemType, IUserType } from 'Api/Dto/Admin/User';
import { IGroupViewModel } from 'Api/Dto/Admin/ViewModels/Groups/GroupViewModel';
import SynchronizeLdapViewModel from 'Api/Dto/Admin/ViewModels/IdentityProvider/SynchronizeLdapViewModel';
import TestLdapViewModel from 'Api/Dto/Admin/ViewModels/IdentityProvider/TestLdapViewModel';
import { IDeleteRoleViewModel } from 'Api/Dto/Admin/ViewModels/Roles/DeleteRoleViewModel';
import { CreateUserViewModel } from 'Api/Dto/Admin/ViewModels/Users/CreateUserViewModel';
import { UpdateUserViewModel } from 'Api/Dto/Admin/ViewModels/Users/UpdateUserViewModel';
import { Identity } from 'Api/Dto/Identity';
import { IdentityVisonRole } from 'Api/Dto/IdentityVisonRole';
import { IListItem } from 'Api/Dto/ListItem';
import { IUserProfile } from 'Api/Dto/Profile';
import { INotificationsUser } from 'Api/Dto/Profile/Notifications';
import { EditLoginViewModel } from 'Api/Dto/Profile/ViewModels/EditLoginViewModel';
import { EditPasswordViewModel } from 'Api/Dto/Profile/ViewModels/EditPasswordViewModel';
import { IPaginationResult, IQueryResult, IQueryResultBase, QueryException, UnauthorizedException } from 'Api/Dto/QueryResult';
import { IIdentity } from 'Api/Dto/Shares';
import { User } from 'Api/Dto/User';
import { IUserPreferences } from 'Api/Dto/UserPreferences';
import HttpClient from 'Api/HttpClient';
import { IResponseHandler } from 'Api/Infrastructure/Interfaces';
import { Routes } from 'Api/Routes';
import { injectTypes } from 'App/injectTypes';
import { IVisonUriService } from 'App/Services/UriServices/Core';
import { inject, injectable } from 'inversify';
import { String } from 'typescript-string-operations';

@injectable()
export class IdentityService {
    constructor(
        @inject(AppContext) appContext: AppContext,
        @inject(HttpClient) httpClient: HttpClient,
        @inject(injectTypes.IVisonUriService) uriService: IVisonUriService,
        @inject(injectTypes.IResponseHandler) responseHandler: IResponseHandler
    ) {
        this._appContext = appContext;
        this._httpClient = httpClient;
        this._uriService = uriService;
        this._responseHandler = responseHandler;
    }

    public async getUserPreferencesAsync(userId: number): Promise<IUserPreferences> {
        let uri = `api/user/${userId}/preferences`

        let response = await this._httpClient.getAsync(uri);
        let jsonResponse = await response.json();

        if (response.status == 400) {
            //TODO handle error and use type queryresult. 
        }

        return jsonResponse.result;
    }

    public async getGroupsAsync(includeSystem: boolean, search: string, page: number, pageSize: number, orderBy?: string): Promise<IPaginationResult<IGroup>> {
        const response = await this._httpClient.getAsync(
            Routes.Api.Groups.Base,
            null,
            {
                includeSystem: includeSystem,
                search: search,
                orderBy: orderBy,
                skip: (page - 1) * pageSize,
                top: pageSize
            }
        );

        const queryResult = await response.json() as IPaginationResult<IGroup>;

        if (response.status != 200) {
            throw new QueryException(queryResult.error);
        }

        return queryResult;
    }

    public async getGroupAsync(groupId: number): Promise<IGroup> {
        const response = await this._httpClient.getAsync(String.Format(Routes.Api.Groups.Group, groupId));
        const jsonResponse = await response.json() as IQueryResult<IGroup>;

        if (response.status != 200) {
            throw new QueryException(jsonResponse.error);
        }

        return jsonResponse.result;
    }

    public async createGroupAsync(addGroupViewModel: IGroupViewModel): Promise<IGroup> {
        const response = await this._httpClient.postAsync(Routes.Api.Groups.Base, addGroupViewModel);
        const jsonResponse = await response.json();

        if (response.status == 400) {
            const queryError = jsonResponse as IQueryResultBase;
            throw new QueryException(queryError.error);
        }
        else if (response.status === 401) {
            throw new UnauthorizedException();
        }

        return (jsonResponse as IQueryResult<IGroup>).result;
    }

    public async editGroupAsync(groupId: number, editGroupViewModel: IGroupViewModel): Promise<void> {
        let url = String.Format(Routes.Api.Groups.Group, groupId);

        const response = await this._httpClient.putAsync(url, editGroupViewModel);

        if (response.status == 400) {
            let jsonResponse = await response.json() as IQueryResultBase;
            throw new QueryException(jsonResponse.error);
        }
        else if (response.status === 401) {
            throw new UnauthorizedException();
        }
    }

    public async deleteGroupsAsync(platformId: number, subscriptionId: number, groupIds: Array<number>): Promise<void> {
        let url = `api/platform/${platformId}/subscription/${subscriptionId}/group`;

        let response = await this._httpClient.deleteAsJsonAsync(groupIds, null, url);

        if (response.status == 400) {
            let jsonResponse = await response.json();
            throw new Error(jsonResponse.error.message);
        }
    }

    public async getGroupMembersAsync(groupId: number, page: number, pageSize: number): Promise<IPaginationResult<User>> {
        let options = {
            skip: (page - 1) * pageSize,
            top: pageSize
        };

        const response = await this._httpClient.getAsync(String.Format(Routes.Api.Groups.Members, groupId), null, options);
        const jsonResponse = await response.json() as IPaginationResult<User>;

        if (response.status != 200) {
            throw new QueryException(jsonResponse.error);
        }

        return jsonResponse;
    }

    public async addMembersToGroupAsync(groupId: number, identitiesIds: Array<number>): Promise<void> {
        let url = String.Format(Routes.Api.Groups.Members, groupId);

        let response = await this._httpClient.postAsync(url, identitiesIds);

        if (response.status == 400) {
            let jsonResponse = await response.json() as IQueryResultBase;
            throw new QueryException(jsonResponse.error);
        }
    }

    public async removeMembersFromGroupAsync(groupId: number, identitiesIds: Array<number>): Promise<void> {
        const url = String.Format(Routes.Api.Groups.Members, groupId);

        const response = await this._httpClient.deleteAsync(url, identitiesIds);

        if (response.status == 400) {
            let jsonResponse = await response.json() as IQueryResultBase;
            throw new QueryException(jsonResponse.error);
        }
    }

    public async getUsersAsync(includeSystem: boolean, search: string, page: number, pageSize: number, orderBy?: string): Promise<IPaginationResult<User>> {
        const response = await this._httpClient.getAsync(
            Routes.Api.Users.Base,
            null,
            {
                includeSystem: includeSystem,
                search: search,
                orderBy: orderBy,
                skip: (page - 1) * pageSize,
                top: pageSize
            }
        );

        const queryResult = await response.json() as IPaginationResult<User>;

        if (response.status != 200) {
            throw new QueryException(queryResult.error);
        }

        return queryResult;
    }

    public async getUserAsync(userGuid: string): Promise<User> {
        const url = String.Format(Routes.Api.Users.User, userGuid);

        const response = await this._httpClient.getAsync(url);
        const queryResult = await response.json() as IQueryResult<User>;

        if (response.status != 200) {
            throw new QueryException(queryResult.error);
        }

        return queryResult.result;
    }

    public async createUserAsync(createUserViewModel: CreateUserViewModel): Promise<User> {
        const response = await this._httpClient.postAsync(
            Routes.Api.Users.Base,
            createUserViewModel,
        );

        const queryResult = await response.json() as IQueryResult<User>;

        if (response.status != 201) {
            throw new QueryException(queryResult.error);
        }

        return queryResult.result;
    }

    public async updateUserAsync(userId: string, viewModel: UpdateUserViewModel): Promise<void> {
        const url = String.Format(Routes.Api.Users.User, userId);

        const response = await this._httpClient.putAsync(url, viewModel);

        if (response.status != 204) {
            const queryError = await response.json() as IQueryResultBase;

            throw new QueryException(queryError.error);
        }
    }

    public async deleteUsersAsync(userIds: Array<string>): Promise<void> {
        const response = await this._httpClient.deleteAsync(
            Routes.Api.Users.Base,
            userIds,
        );

        if (response.status != 204) {
            const queryError = await response.json() as IQueryResultBase;
            throw new QueryException(queryError.error);
        }
    }

    public async deleteAvatarAsync(userGuid: string): Promise<void> {
        const url = String.Format(Routes.Api.Users.Avatar, userGuid);

        const response = await this._httpClient.deleteAsync(url, null);

        if (response.status != 204) {
            const queryError = await response.json() as IQueryResultBase;
            throw new QueryException(queryError.error);
        }
    }

    public async searchIdentitiesAsync(search: string): Promise<Array<Identity>> {
        let url = this._buildSearchUrl(search);

        let response = await this._httpClient.getAsync(url);

        if (!response.ok) {
            throw Error('Unhandled SearchIdentitiesAsync:' + response.statusText);
        }

        let jsonResult = await response.json();

        return jsonResult as Array<Identity>;
    }

    public async searchPublicUsersAsync(search: string, page: number, pageSize: number = 100): Promise<IPaginationResult<IIdentity>> {
        const response = await this._httpClient.getAsync(
            Routes.Api.Users.Public,
            null,
            {
                search: search,
                skip: (page - 1) * pageSize,
                top: pageSize
            }
        );

        return await this._responseHandler
            .handleResponseAsync<IPaginationResult<IIdentity>>(response);
    }

    public async getSubscriptionsAsync(): Promise<Array<ISubscription>> {
        let response = await this._httpClient.getAsync(`api/platform/${this._appContext.platformId}/subscription`);
        let jsonResponse = await response.json();

        if (response.status == 400) {
            //TODO handle error and use type queryresult. 
        }

        return jsonResponse.result as Array<ISubscription>;
    }

    public async getIdentityProvidersAsync(): Promise<Array<IIdentityProvider>> {
        let response = await this._httpClient.getAsync(`api/platform/${this._appContext.platformId}/provider`);
        let jsonResponse = await response.json();

        if (response.status == 400) {
            //TODO handle error and use type queryresult. 
        }

        return jsonResponse.result as Array<IIdentityProvider>;
    }

    public async getRolesAsync(): Promise<Array<IdentityVisonRole>> {
        let response = await this._httpClient.getAsync(`api/platform/${this._appContext.platformId}/role`);
        let jsonResponse = await response.json();

        if (response.status == 400) {
            //TODO handle error and use type queryresult. 
        }

        return jsonResponse.result as Array<IdentityVisonRole>;
    }

    public async getPaginatedRolesAsync(search: string, sortOrder: SortOptions, page: number, pageSize: number): Promise<Array<IdentityVisonRole>> {
        let options = {
            search: search,
            sortOrder: sortOrder,
            skip: (page - 1) * pageSize,
            top: pageSize
        };

        let response = await this._httpClient.getAsync(`api/platform/${this._appContext.platformId}/role`, null, options);
        let jsonResponse = await response.json();

        if (response.status == 400) {
            //TODO handle error and use type queryresult. 
        }

        return jsonResponse.result as Array<IdentityVisonRole>;
    }

    public async getRolesWithUsersAndAvailableRolesAsync(roleIds: Array<string>): Promise<IRolesWithUsersAndAvailableRoles> {
        let options = {
            roleIds: roleIds,
            actionType: 'delete'
        }

        let response = await this._httpClient.getAsync(`api/platform/${this._appContext.platformId}/role`, null, options);
        let jsonResponse = await response.json();

        if (response.status == 400) {
            //TODO handle error and use type queryresult. 
        }

        return jsonResponse.result as IRolesWithUsersAndAvailableRoles;
    }

    public async deleteRolesAsync(platformId: number, deleteRoleViewModel: IDeleteRoleViewModel): Promise<void> {
        const response = await this._httpClient.postFormDataAsync(String.Format(Routes.Api.Roles.Delete, platformId), deleteRoleViewModel);

        if (response.status != 204) {
            let queryError = await response.json() as IQueryResultBase;

            throw new Error(queryError.error.message);
        }
    }

    public async getUserTypesAsync(): Promise<Array<IUserType>> {
        let response = await this._httpClient.getAsync('api/list/userTypes');
        let jsonResponse = await response.json();

        if (response.status == 400) {
            //TODO handle error and use type queryresult. 
        }

        return jsonResponse.result as Array<IUserType>;
    }

    public async getInternalSystemTypesAsync(): Promise<Array<IInternalSystemType>> {
        let response = await this._httpClient.getAsync('api/list/InternalSystemTypes');
        let jsonResponse = await response.json();

        if (response.status == 400) {
            //TODO handle error and use type queryresult. 
        }

        return jsonResponse.result as Array<IInternalSystemType>;
    }

    public async getUserGroupsAsync(userGuid: string, page: number, pageSize: number): Promise<IPaginationResult<IGroup>> {
        const options = {
            skip: (page - 1) * pageSize,
            top: pageSize
        };

        const response = await this._httpClient.getAsync(String.Format(Routes.Api.Users.Groups, userGuid), null, options);
        const jsonResponse = await response.json();

        if (response.status == 400) {
            throw new QueryException((jsonResponse as IQueryResultBase).error);
        }

        return jsonResponse as IPaginationResult<IGroup>
    }

    public async getNotificationsDetailsAsync(): Promise<Array<IListItem>> {
        const response = await this._httpClient.getAsync(Routes.Api.List.Notifications);
        const jsonResponse = await response.json();

        if (response.status != 200) {
            const queryError = jsonResponse as IQueryResultBase;

            throw new QueryException(queryError.error);
        }

        return (jsonResponse as IQueryResult<Array<IListItem>>).result;
    }

    public async getCurrentNotificationSettingsAsync(): Promise<INotificationsUser> {
        const url = String.Format(Routes.Api.Users.Notifications, this._appContext.user.id);
        const response = await this._httpClient.getAsync(url);
        const jsonResponse = await response.json();

        if (response.status != 200) {
            const queryError = jsonResponse as IQueryResultBase;

            throw new QueryException(queryError.error);
        }

        return (jsonResponse as IQueryResult<INotificationsUser>).result;
    }

    public async updateNotificationSettingsAsync(notificationsUser: INotificationsUser): Promise<void> {
        const url = String.Format(Routes.Api.Users.Notifications, this._appContext.user.id);
        const response = await this._httpClient.postFormDataAsync(url, notificationsUser);

        if (response.status != 204) {
            const queryError = await response.json() as IQueryResultBase;

            throw new QueryException(queryError.error);
        }
    }

    public async resetPasswordAsync(viewModel: ResetPasswordViewModel): Promise<void> {
        const response = await this._httpClient.postFormDataAsync('account/resetpassword', viewModel);

        if (response.status != 204) {
            const queryError = await response.json() as IQueryResultBase;

            throw new QueryException(queryError.error);
        }
    }

    public async forceResetPasswordAsync(viewModel: ForceResetPasswordViewModel): Promise<void> {
        const response = await this._httpClient.postFormDataAsync('/account/forceresetpassword', viewModel);

        if (response.status != 204) {
            const queryError = await response.json() as IQueryResultBase;

            throw new QueryException(queryError.error);
        }
    }

    public async resetUserPasswordAsync(userId: number, resetPasswordType: GenerateType, password: string, notifyUser: boolean): Promise<void> {
        const response = await this._httpClient.postFormDataAsync(
            'administratorPortal/users/resetpassword',
            {
                userId: userId,
                generateType: resetPasswordType,
                generatedPassword: password,
                notifyUser: notifyUser
            }
        )

        if (response.status != 200) {
            const queryError = await response.json() as IQueryResultBase;

            throw new QueryException(queryError.error);
        }
    }

    public async testLdapConfigAsync(platformId: number, configuration: TestLdapViewModel): Promise<Array<{ [key: string]: string }>> {
        const response = await this._httpClient.postFormDataAsync(String.Format(Routes.Api.Platforms.IdentityProviders.TestLdap, platformId), configuration);
        const jsonResponse = await response.json();

        if (response.status != 200) {
            let queryError = jsonResponse as IQueryResultBase;
            throw new QueryException(queryError.error);
        }

        return (jsonResponse as IQueryResult<Array<{ [key: string]: string }>>).result;
    }

    public async synchronizeLdapUsersAsync(platformId: number, identityProviderId: number, viewModel: SynchronizeLdapViewModel): Promise<Array<ILdapSynchronizationResult>> {
        const url = String.Format(Routes.Api.Platforms.IdentityProviders.SynchronizeLdap, platformId, identityProviderId);
        const response = await this._httpClient.postFormDataAsync(url, viewModel);

        if (response.status != 201) {
            throw new QueryException({
                code: response.status.toString(),
                message: response.statusText,
                details: [],
                innerError: null,
                target: null
            });
        }

        const jsonResponse = await response.json() as IQueryResult<Array<ILdapSynchronizationResult>>;
        return jsonResponse.result;
    }

    public async getUserProfileAsync(userId: number): Promise<IUserProfile> {
        const url = String.Format(Routes.Api.Users.Profile, userId)
        const response = await this._httpClient.getAsync(url);
        const jsonResponse = await response.json();

        if (response.status != 200) {
            let queryError = jsonResponse as IQueryResultBase;
            throw new QueryException(queryError.error);
        }

        return (jsonResponse as IQueryResult<IUserProfile>).result;
    }

    public async updateUserBannerAsync(userId: number, banner: File): Promise<string> {
        const data = {
            banner: banner,
            id: userId
        };

        const response = await this._httpClient.postFormDataAsync('profile/profile/editbanner', data);

        if (response.status != 201) {
            const queryError = await response.json() as IQueryResultBase;
            throw new QueryException(queryError.error);
        }

        return response.headers.get('location');
    }

    public async deleteUserBannerAsync(userId: number): Promise<void> {
        const data = {
            banner: null,
            id: userId
        }

        const response = await this._httpClient.postFormDataAsync('profile/profile/deletebanner', data);

        if (response.status != 204) {
            const queryError = await response.json() as IQueryResultBase;
            throw new QueryException(queryError.error);
        }
    }

    public async deleteGroupsOfUserAsync(userGuid: string, groupIds: Array<number>): Promise<void> {
        const url = String.Format(Routes.Api.Users.Groups, userGuid);

        const response = await this._httpClient.deleteAsync(url, groupIds);

        if (response.status != 204) {
            const queryError = await response.json() as IQueryResultBase;
            throw new QueryException(queryError.error);
        }
    }

    public async deleteOwnAccountAsync(password: string): Promise<void> {
        const url = 'profile/profile/confirmdelete';

        const data = {
            password: password
        };

        const response = await this._httpClient.postFormDataAsync(url, data);

        if (response.status != 204) {
            const queryError = await response.json() as IQueryResultBase;
            throw new QueryException(queryError.error);
        }
    }

    public async editLoginAsync(editLoginViewModel: EditLoginViewModel): Promise<void> {
        const url = 'profile/profile/editlogin';

        const response = await this._httpClient.postFormDataAsync(url, editLoginViewModel);

        if (response.status != 204) {
            const queryError = await response.json() as IQueryResultBase;
            throw new QueryException(queryError.error);
        }
    }

    public async editPasswordAsync(editPasswordViewModel: EditPasswordViewModel): Promise<void> {
        const url = 'profile/profile/editpassword';

        const response = await this._httpClient.postFormDataAsync(url, editPasswordViewModel);

        if (response.status != 204) {
            const queryError = await response.json() as IQueryResultBase;
            throw new QueryException(queryError.error);
        }
    }

    public exportUsersCsv(platformId: number, subscriptionId: number, userIds?: Array<number>): void {
        let form = document.createElement('form');
        form.action = this._uriService.getAbsoluteUri(
            Routes.Api.Users.Export,
            {
                'platformId': platformId.toString(),
                'subscriptionId': subscriptionId.toString()
            }
        );
        form.method = 'POST';
        form.setAttribute('download', null);
        form.target = '_blank';

        if (userIds) {
            for (let user of userIds) {
                let input = document.createElement('input');
                input.type = 'hidden';
                input.name = 'userIds';
                input.value = user.toString();

                form.appendChild(input);
            }
        }

        let body = document.querySelector('body');
        body.appendChild(form);
        form.submit();
        body.removeChild(form);
    }

    private _buildSearchUrl(searchText: string): string {
        let url = `/administratorportal/users/searchidentities/?searchString=${searchText}`;

        let platformId = this._appContext.platformId;

        if (platformId) {
            url += `&platformId=${platformId}`;
        }

        let subscriptionId = this._appContext.subscriptionId;

        if (subscriptionId) {
            url += `&subscriptionId=${subscriptionId}`;
        }

        return url;
    }

    protected readonly _appContext: AppContext;
    protected readonly _httpClient: HttpClient;
    protected readonly _uriService: IVisonUriService;
    protected readonly _responseHandler: IResponseHandler;
}

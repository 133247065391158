import { AssetDirectory, AssetFile, AssetItem, AssetItemType, AssetPanoramaGroup, AssetProject, FileMap, FormType, Navigation, NavigationType, PanoramaItemLink, AssetHotspot as ProjectAssetHotspot, AssetPanoramaVersion as ProjectAssetPanoramaVersion, RendererHelper } from 'Api/Contracts/Dtos';
import { FileType } from 'Api/Dto/Drive';
import { CoordinateSystem, ICoordinates } from 'Api/Dto/Project';
import { AssetMapArea, AssetHotspot as ExploreAssetHotspot, AssetPanoramaVersion as ExploreAssetPanoramaVersion } from 'Api/ExploreService';
import HttpClient from 'Api/HttpClient';
import CoordinatesHelper from 'App/Helpers/CoordinatesHelper';
import { IHotspot, IPlanCoordinates, ISphericalCoordinates } from 'Framework/Components/Controls/XView/Core';
import { XMLParser } from 'fast-xml-parser';

type AssetHotspot = ProjectAssetHotspot | ExploreAssetHotspot;
type AssetPanoramaVersion = ProjectAssetPanoramaVersion | ExploreAssetPanoramaVersion;

export function getPdfUrl(cultureName: string, guid: string): string {
    return `/${cultureName}/asset/pdf/${guid}`;
}

export function getPreviewUrl(cultureName: string, guid: string): string {
    return `/${cultureName}/asset/preview/${guid}`;
}

export function getVideoUrl(cultureName: string, guid: string): string {
    return `/${cultureName}/asset/video/${guid}`;
}

export function getPanoramaUrl(cultureName: string, guid: string): string {
    return `/${cultureName}/asset/panorama/${guid}/%lvl/%s/%h/%v`;
}

export function getImageUrl(cultureName: string, guid: string, quality: 'original' | 'optimized'): string {
    return `/${cultureName}/asset/image/${guid}?quality=${quality}`;
}

export function getDeepZoomImageConfigurationUrl(cultureName: string, guid: string): string {
    return `/${cultureName}/asset/deepzoom/${guid}`;
}

export function getDeepZoomImageUrlwithParameters(cultureName: string, guid: string): string {
    return `/${cultureName}/asset/deepzoom/${guid}/%lvl/%tile`;
}

export function getDeepZoomImageUrl(cultureName: string, guid: string, level: number, tile: number): string {
    return `/${cultureName}/asset/deepzoom/${guid}/${level}/${tile}`;
}

export function getPanoramaMobileUrl(cultureName: string, guid: string): string {
    return `/${cultureName}/asset/panorama/${guid}/%lvl/%s`;
}

export function getIconForItem(item: AssetItem, open: boolean = null): string {
    if (item instanceof ProjectAssetHotspot) {
        return RendererHelper.isIconRenderer(item.content) ? item.content.icon : 'fa-light fa-draw-polygon';
    }
    else if (item instanceof ProjectAssetPanoramaVersion) {
        return 'fa-light fa-globe';
    }
    else if (item instanceof Navigation) {
        return getNavigationIcon(item);
    }
    else if (item instanceof FileMap) {
        return 'fa-light fa-map';
    }
    else if (item instanceof AssetProject) {
        return 'fa-solid fa-pen-ruler';
    }
    else if (item instanceof PanoramaItemLink) {
        return 'fa-light fa-globe';
    }
    else if (item instanceof AssetPanoramaGroup) {
        return 'fa-light fa-book-atlas';
    }
    else if (item instanceof AssetDirectory) {
        if (item.isDefaultSmartObjectDirectory) {
            return 'fa-light fa-cubes';
        }

        return open ? 'fa-solid fa-folder-open' : 'fa-solid fa-folder';
    }
    else if (item instanceof AssetFile) {
        return getFileIcon(item);
    }

    return null;
}

export function getNavigationIcon(item: Navigation): string {
    if (item.navigationType == NavigationType.List) {
        return 'fa-light fa-list';
    }
    else if (item.navigationType == NavigationType.Map) {
        return 'fa-light fa-map-location-dot';
    }
    return null;
}

export function getFileIcon(assetFile: AssetFile): string {
    let fileType = assetFile.file.fileType;

    if (fileType == FileType.Symlink) {
        fileType = assetFile.file.target.fileType;
    }

    if (fileType == FileType.Image) {
        return 'fa-solid fa-file-image';
    }
    else if (fileType == FileType.Office) {
        switch (assetFile.file.extension) {
            case '.xlsx':
                return 'fa-solid fa-file-excel';
            case '.pptx':
                return 'fa-solid fa-file-powerpoint';
            case '.docx':
                return 'fa-solid fa-file-word';
            default:
                return 'fa-solid fa-file';
        }
    }
    else if (fileType == FileType.Pdf) {
        return 'fa-solid fa-file-pdf';
    }
    else if (fileType == FileType.Video) {
        return 'fa-solid fa-file-video';
    }
    else if (fileType == FileType.Audio) {
        return 'fa-solid fa-file-audio';
    }
    else {
        return 'fa-solid fa-file';
    }
}

export function getIconColorForItem(item: AssetItem, open: boolean = null): string {
    switch (item.type) {
        case AssetItemType.Directory:
            const directory: AssetDirectory = item as AssetDirectory;

            if (directory.isDefaultSmartObjectDirectory) {
                return null;
            }

            return 'amber';
        case AssetItemType.PanoramaItemLink:
        case AssetItemType.PanoramaVersion:
        case AssetItemType.PanoramaGroup:
            return 'blue';
        case AssetItemType.Hotspot:
            return (item as AssetHotspot).content.color;
        case AssetItemType.Project:
            return 'green';
        case AssetItemType.Navigation:
            return 'purple';
        default:
            return null;
    }
}

export function makeLocalHotspot<T extends AssetHotspot>(globalHotspot: T, destinationVersion: AssetPanoramaVersion): T {
    let clone: T;

    if (globalHotspot instanceof ProjectAssetHotspot
        && destinationVersion instanceof ProjectAssetPanoramaVersion
    ) {
        clone = ProjectAssetHotspot.fromJson(globalHotspot) as T;
    }
    else if (globalHotspot instanceof ExploreAssetHotspot
        && destinationVersion instanceof ExploreAssetPanoramaVersion
    ) {
        clone = ExploreAssetHotspot.fromJson(globalHotspot) as T;
    }
    else {
        throw new Error('Mixing Dto.Project and Dto.Explore is forbidden. globalHotspot, oldReference and destinationVersion must all extend the same AssetItem type.');
    }

    clone.parent = destinationVersion;
    clone.parentId = destinationVersion.assetItemId;
    clone.coordinates = alignGlobalHotspotCoordinates(globalHotspot, 0, destinationVersion.offset);

    return clone;
}

export function alignGlobalHotspotCoordinates(hotspot: AssetHotspot, oldOrigin: number, newOrigin: number): Array<ICoordinates> {
    if (hotspot.coordinateSystem == CoordinateSystem.Spherical) {
        return hotspot.coordinates.map(
            c => CoordinatesHelper.changeAzimuthBase(
                c as ISphericalCoordinates,
                oldOrigin,
                newOrigin
            )
        );
    }

    return hotspot.coordinates;
}

export async function getImageSizeAsync(httpClient: HttpClient, codeCulture: string, guid: string, token: string = null): Promise<{ width: number, height: number }> {
    const result = await httpClient.getAsync(
        getDeepZoomImageConfigurationUrl(codeCulture, guid),
        null,
        { token: token }
    );

    const parser = new XMLParser({ ignoreAttributes: false });
    const jObj = parser.parse(await result.text());

    return {
        width: parseInt(jObj.Image.Size['@_Width'], 10),
        height: parseInt(jObj.Image.Size['@_Height'], 10)
    };
}

export function toIHotspot(hotspot: AssetHotspot): IHotspot {
    const result: IHotspot & {assetItemLinkedId?:number} = {
        coordinateSystem: hotspot.coordinateSystem,
        coordinates: hotspot.coordinates,
        name: hotspot.name,
        content: hotspot.content,
        events: hotspot.events,
        onclick: null,
        assetItemLinkedId: hotspot.assetItemLinkedId
    };

    if (hotspot instanceof AssetMapArea) {
        const coordinates = hotspot.areaCoordinates;

        const type = parseInt(hotspot.content.formType, 10);

        switch (type) {
            case FormType.Rectangle:
                const origin: IPlanCoordinates = coordinates[0] as IPlanCoordinates;
                const size: any = coordinates[1];

                result.coordinates = [
                    origin,
                    { x: origin.x + size.w, y: origin.y + size.h },
                    { x: origin.x + size.w, y: origin.y },
                    { x: origin.x, y: origin.y + size.h }
                ];

                break;
            case FormType.Circle:
                const center: any = coordinates[0];
                const rayon: any = coordinates[1];

                //Generate the coordinates of the circle in the plan
                result.coordinates = [...generatePointsOnCircumference(
                    { x: center.cx, y: center.cy },
                    rayon.r,
                    100)
                ];

                break;
            case FormType.Polygon:
                const points: any = coordinates[0];

                result.coordinates = points.points;
                break;
            default:
        }
    }

    if (hotspot.content.size) {
        if (!(hotspot.content.size instanceof Number)) {
            result.content.size = Number.parseInt(hotspot.content.size, 10);
        }
    }
    else {
        result.content.size = 20;
    }

    return result;
}

function* generatePointsOnCircumference(center: IPlanCoordinates, radius: number, numberOfPoints: number): IterableIterator<IPlanCoordinates> {
    for (let i = 0; i < numberOfPoints; i++) {
        const theta = 2 * Math.PI * (i / numberOfPoints); // angle in radians

        yield {
            x: center.x + radius * Math.cos(theta),
            y: center.y + radius * Math.sin(theta)
        };
    }
}